<template lang="pug">
v-card.pa-4
  TableV2(
    :headers="headers"
    :items="items.results || []"
    :pageCount="items.count"
    :currentPage="items.current"
    :querySearch="getData"
    :actions="actions"
    :isLoading="isLoading"
    )
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { checkAccess } from '@/mixins/permissions'

export default {
  data () {
    return {
      headers: [
        { value: 'sailorFullName', text: this.$t('sailorFullName'), sortable: false },
        { value: 'sailorFullNameEng', text: this.$t('tableHeaders.sailor_full_name_eng'), sortable: false },
        { value: 'created_at', text: this.$t('createDate') },
        { value: 'sailorID', text: this.$t('sailorId'), sortable: false },
        { value: 'crewing_company', text: this.$t('crewing'), sortable: false },
        { value: 'manager_full_name', text: this.$t('crewingManager'), sortable: false },
        { value: 'rank', text: this.$t('rank'), sortable: false },
        { value: 'list_positions', text: this.$t('position'), sortable: false },
        { value: 'include_sailor_passport', text: this.$t('sailorPassport'), sortable: false },
        { value: 'status_document', text: this.$t('tableHeaders.status_document') },
        { value: 'event', width: '60px', sortable: false }
      ],
      actions: [
        { id: 1,
          tooltip: 'openSailor',
          action: ({ sailor }) => {
            const routeData = this.$router.resolve({ name: 'sailor', params: { id: sailor.id } })
            window.open(routeData.href)
          },
          color: 'blue',
          name: 'mdi-account-outline',
          checkAccess: (item) => item.sailor?.id },
        { id: 2,
          tooltip: 'model-PacketItem',
          action: (item) => {
            const routeData = this.$router.resolve({ name: 'position-statements-info', params: { id: item.sailor.id, documentID: item.packet_item } })
            window.open(routeData.href)
          },
          color: 'blue',
          name: 'mdi-file-search-outline',
          checkAccess: (item) => item.sailor?.id && item.packet_item },
        { id: 3,
          tooltip: 'go',
          to: ({ id }) => ({ name: 'isc-package-statement-info', params: { id }, query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          checkAccess: (item) => item.sailor?.id && !item.packet_item }
      ],
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      items: state => state.crewing.statementsPackageISC
    })
  },
  beforeCreate () {
    this.$store.commit('setActivePage', { name: 'packageStatementISC', access: checkAccess('menuItem-packageStatementISC') })
  },
  methods: {
    ...mapActions(['getStatementsPackageISC']),

    getData (filter) {
      this.isLoading = true
      this.getStatementsPackageISC(filter).finally(() => { this.isLoading = false })
    }
  }
}
</script>
